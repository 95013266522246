<template>
  <surround-box :class="`weather-pane${collapse ? ' closed closedH' : ''}`">
    <div class="collapse" @click="collapseBox">
      <Icon
        :style="`transform: rotate(${collapse ? 0 : -180}deg)`"
        type="ios-arrow-back"
      />
    </div>
    <div
      :class="`collapseTitle${collapse ? ' collTitleOpen' : ''}`"
      v-if="collapse"
    >
      {{ menuName }}
    </div>
    <div class="inner">
      <a class="close" @click="() => $emit('hidden')">X</a>
      <p class="title">
        <img src="/imgs/title.gif" alt="" />
        {{ menuName }}
      </p>
      <div class="preday-weather-pane">
        <div
          class="preday-weather-item"
          v-for="(weather, index) in predayWeather"
          :key="index"
        >
          <span class="iconfont">{{ weather.tempDay }}&#xe767;</span>
          <span>
            <img :src="weather.url" />
          </span>
          <span>{{ weather.condition }}</span>
          <span>{{ weather.predictDate.split('-')[1]+'-'+weather.predictDate.split('-')[2] }}</span>
        </div>
      </div>
      <div class="statistics-pane">
        <div>
          <span
            :class="{ active: isActive === 1 }"
            @click="() => chartChange(1)"
            >24小时天气</span
          >
          <span
            :class="{ active: isActive === 5 }"
            @click="() => chartChange(5)"
            >空气质量</span
          >
          <span
            :class="{ active: isActive === 6 }"
            @click="() => chartChange(6)"
            >灾害预警</span
          >
        </div>
        <div v-if="isActive === 1">
          <div
            ref="weatherLine"
            class="chart-container"
          ></div>
        </div>
        <div class="quality-container" v-if="isActive === 5">
          <div class="quality-item">
            <div>
              <p>CO</p>
              <p>一氧化碳</p>
            </div>
            <div>
              {{ qualityData.co }}
              <span>mg/m3</span>
            </div>
          </div>
          <div class="quality-item">
            <div>
              <p>NO2</p>
              <p>二氧化氮</p>
            </div>
            <div>
              {{ qualityData.no2 }}
              <span>mg/m3</span>
            </div>
          </div>
          <div class="quality-item">
            <div>
              <p>O3</p>
              <p>臭氧</p>
            </div>
            <div>
              {{ qualityData.o3 }}
              <span>mg/m3</span>
            </div>
          </div>
          <div class="quality-item">
            <div>
              <p>PM2.5</p>
              <p>细颗粒物</p>
            </div>
            <div>
              {{ qualityData.pm25 }}
              <span>μg/m³</span>
            </div>
          </div>
          <div class="quality-item">
            <div>
              <p>PM1.0</p>
              <p>超细颗粒物</p>
            </div>
            <div>
              {{ qualityData.pm10 }}
              <span>mg/m3</span>
            </div>
          </div>
          <div class="quality-item">
            <div>
              <p>SO2</p>
              <p>二氧化硫</p>
            </div>
            <div>
              {{ qualityData.so2 }}
              <span>mg/m3</span>
            </div>
          </div>
        </div>
        <div class="warning-container" v-if="isActive === 6">
          <div class="warning-item">
            <img src="imgs/empty/empty.png" alt="" />
            <p>暂无预警消息</p>
          </div>
          <!-- <div
            v-if="isActive === 6"
            class="warning-item"
            v-for="(warning, index) in warningData"
            :key="index"
          >
            <div>
              <img :src="warning.img" />
            </div>
            <div class="content-box">
              <p>{{warning.content}}</p>
              <p>{{warning.source}} {{warning.releaseTime}}</p>
            </div>
            <div
              :class="[warning.level === 1 ? 'level1' : warning.level === 2 ? 'level2' : warning.level === 3 ? 'level3' : 'level4']"
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <p style="font-size: 12px; margin-top: 10px">{{warning.levelName}}</p>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox";
import * as echarts from "echarts";
// import quality from "./quality";
import warning from "./warning";

export default {
  components: {
    surroundBox,
  },
  props: {
    longitude: [String, Number],
    latitude: [String, Number],
    menuName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      predayWeather: [],
      isActive: 1,
      qualityData: {},
      warningData: warning.list,
      xData: [],
      sData: [],

      collapse: false,
    };
  },
  methods: {
    collapseBox() {
      this.collapse = !this.collapse;
    },
    //   获取七天气象预报
    getPredayWeather() {
      this.$post(this.$api.COMMON.WEATHER, {
        type: 3,
        lon: this.longitude,
        lat: this.latitude,
      }).then((res) => {
        let result = res.forecast.map((item) => ({
          tempDay: item.tempDay,
          condition: item.conditionDay,
          url: `/imgs/iot/weather/W${item.conditionIdDay}.png`,
          predictDate: item.predictDate,
        }));
        result = result.slice(1, 6);
        this.predayWeather = result;
      });
    },

    initWeatherLine() {
      let myChart = echarts.init(this.$refs.weatherLine);
      let option = {
        grid: {
          left: "3%",
          right: "1%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            return params[0].name + "时" + ": " + params[0].value + "℃";
          },
        },
        xAxis: {
          type: "category",
          data: this.xData,
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "℃",
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        color: ["#08DDDD"],
        series: [
          {
            data: this.sData,
            type: "line",
            smooth: true,
          },
        ],
      };
      myChart.setOption(option);
    },
    chartChange(val) {
      let xData = [];
      let sData = [];
      this.isActive = val;
      this.$post(this.$api.COMMON.WEATHER, {
        type: val,
        lon: this.longitude,
        lat: this.latitude,
      }).then((res) => {
        if (val === 1) {
          res.hourly.forEach((item) => {
            xData.push(item.hour);
            sData.push(parseInt(item.temp));
          });
          this.xData = xData.slice(0, 24);
          this.sData = sData.slice(0, 24);
          this.initWeatherLine();
        } else if (val === 5) {
          this.qualityData = res.aqi;
        } else if (val === 6) {
          console.log(6);
        }
      });
    },
  },
  mounted() {
    this.getPredayWeather();
    // this.initWeatherLine();
    this.chartChange(1);
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
.closed {
  width: 132px !important;
}
.closedH {
  max-height: 40px !important;
}
</style>