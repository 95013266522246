export default {
  list: [
    {
      content: '暴雨蓝色预警 江西浙江局地有大暴雨',
      source: '中国天气网',
      img: '../../../../static/imgs/warning/blue.png',
      level: 1,
      levelName: '蓝色一级预警',
      releaseTime: '2020-05-29 10:14'
    },
    {
      content: '暴雨黄色预警 江西浙江局地有大暴雨',
      source: '中国天气网',
      img: '../../../../static/imgs/warning/yellow.png',
      level: 2,
      levelName: '黄色二级预警',
      releaseTime: '2020-05-29 10:14'
    },
    {
      content: '暴雨橙色预警 江西浙江局地有大暴雨',
      source: '中国天气网',
      img: '../../../../static/imgs/warning/orange.png',
      level: 3,
      levelName: '橙色三级预警',
      releaseTime: '2020-05-29 10:14'
    },
  ]
}